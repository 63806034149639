import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthGuard } from './Services/auth_guard/auth_guard.service';
import { environment } from 'src/environments/environment';



const routes: Routes = [
  
  // Home 1 Path
  { 
    path: "", 
    loadChildren: () => import("./Lazy-loaded-Modules/Home-Modules/home/home.module").then( m => m.HomeModule ), 
    data: {name: 'default', roles: ['haven', 'tams','mayan', 'nesma','dosapark']}, 
    canActivate: [AuthGuard] 
  },

  // Home 2 Path
  { 
    path: "", 
    loadChildren: () => import("./Lazy-loaded-Modules/Home-Modules/home-two/home-two.module").then( m => m.HomeTwoModule ), 
    data: {name: 'default', 
    roles: ['earthyworthy','bbold', 'deera','accel','karupattiraja','cle','linenraj','bestgifts','kubendran','shubhcards','aiema','beaubelle','bombayhardware','avonseals','specialwires','carience','vimpro']}, 
    canActivate: [AuthGuard] 
  },

  {
    path: 'home-two',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/Home-Modules/home-two/home-two.module').then(m => m.HomeTwoModule)
  },
    // Login Page
  {
    path: "login-new",
    data: { name: "default", roles: ['all'] },
    loadChildren: () => import("./Lazy-loaded-Modules/Account-Modules/login-new/login-new.module").then(m => m.LoginNewModule),
    canActivate: [AuthGuard]
  },

  // New Register page
  {
    path: "member-register",
    data: { name: "default", roles: ['all'] },
    loadChildren: () => import("./Lazy-loaded-Modules/Account-Modules/member-register/member-register.module").then(m => m.MemberRegisterModule),
    canActivate: [AuthGuard]
  },

  // New Register page
  {
    path: "forgot-password",
    data: { name: "default", roles: ['all'] },
    loadChildren: () => import("./Lazy-loaded-Modules/Account-Modules/user-forgot-password/user-forgot-password.module").then(m => m.UserForgotPasswordModule),
    canActivate: [AuthGuard]
  },


  // FAQ page
  {
    path: "FAQs",
    data: { name: "default", roles: ['all']},
    loadChildren: () => import("./Lazy-loaded-Modules/static-pages/faqs/faqs.module").then(m => m.FaqsModule),
    canActivate: [AuthGuard]
  },

  // Products List page
  {
    path: "",
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/Product-Modules/product-lists/products-list-page.module').then(m => m.ProductsListPageModule)
  },

  // Products List page
  {
    path: "",
    data: {name: 'default', roles: ['all']}, 
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Product-Modules/product-details/product-details.module').then(m => m.ProductDetailsModule)
  },

  // Product Reviews
  
  // Adding a Product Review
  { 
    // path: "add-review/:id/:pid/:i", 
    path: "add-review", 
    data: {name: 'default', roles: ['all']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/Add-review/add-review.module').then(m => m.AddReviewModule),
    canActivate: [AuthGuard] 
  },

  // Orders page
  {
    path: "",
    // data: {name: 'default', roles: ['tams','haven','earthyworthy','bbold','mayan', 'deera','karupattiraja','bestgifts','kubendran','shubhcards','vistas','beaubelle','bombayhardware','avonseals','specialwires','carience','vimpro','linenraj']}, 
     data: {name: "default", roles: ["all"]},
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Order-Modules/order.module').then(m => m.OrderModule)
  },

  // Checkout Page
  {
    path: "checkout-new",
    // data: {name: 'default', roles: ['dosapark','haven', 'nesma', 'tams','earthyworthy','bbold', 'luckme', 'murgasen','beaubelle', 'deera','specialwires','bestgifts','kubendran','shubhcards', 'mayan','carience','vimpro','karupattiraja','vistas','linenraj']},
     data: {name: "default", roles: ["all"]},
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Checkout-new/checkout.module').then(m => m.CheckoutModule)
  },

  // Cart Page
  {
    path: "cart", 
    data: {name: "default", roles: ["all"]},
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Cart-module/cart.module').then(m => m.CartModule)
  },

  // Wishlist 
  {
    path: "",
    data: {name: "default", roles: ["all"]},
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Account-Modules/wishlist/wishlist.module').then(m => m.WishlistModule)
  },
  
  // My account Page
  {
    path: "my-account",
    data: {name: "default", roles: ["all"]},
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Account-Modules/my-account/my-account.module').then(m => m.MyAccountModule)
  },

  {
    path: 'my-orders', 
    data: {name: 'default', roles: ['all']}, 
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Order-Modules/my-orders/my-orders.module').then(m => m.MyOrdersModule)
  },

  {
    path: 'my-order-details/:id', 
    data: {name: 'default', roles: ['all']}, 
    canActivate: [AuthGuard],
    loadChildren: () => import('./Lazy-loaded-Modules/Order-Modules/my-order-details/my-order-details.module').then(m => m.MyOrderDetailsModule)
  },

  //  Lazy Loaded static pages
  //  About me page
  {
    path: 'about-us',
    data: {name: 'default', roles: ['haven', 'tams','mayan', 'aiema','beaubelle','bombayhardware','cle','linenraj','avonseals','specialwires','karupattiraja','shubhcards', 'deera','accel','bestgifts','kubendran','dosapark','bbold','nesma','carience','vimpro']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/about-us/about-us.module').then(m => m.aboutUsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'investor',
    data: {name: 'default', roles: ['cle']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/investor/investor.module').then(m => m.investorModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'about-us',
    data: {name: 'default', roles: ['earthyworthy']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/earthy-worthy-aboutus/earthy-worthy-aboutus.module').then(m => m.EarthyWorthyAboutUsModule),
    canActivate: [AuthGuard]
  },
  
  //  Cancellation page
  {
    path: 'cancellation-policy',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/cancellation-policy/cancellation-policy.module').then(m => m.CancellationPolicyModule),
  },
  
  //  Contact Us page
  {
    path: 'contact-us',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
    canActivate: [AuthGuard]
  },
// Feedback Page
{
  path: 'feedback-form',
  data: {name: 'default', roles: ['all']},
  loadChildren: () => import('./Lazy-loaded-Modules/static-pages/feedback-form/feedback-form.module').then(m => m.FeedbackFormModule),
  canActivate: [AuthGuard]
},
  //  Privacy policy page
  {
    path: 'privacy-policy',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    canActivate: [AuthGuard]
  },
  
  //  Return policy page
  {
    path: 'return-policy',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/return-policy/return-policy.module').then(m => m.ReturnPolicyModule),
    canActivate: [AuthGuard]
  },
  
  //  Services page
  {
    path: 'services',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/services/services.module').then(m => m.ServicesModule),
    canActivate: [AuthGuard]
  },

  // Table Booking for haven
  {
    path: "table-booking",
    data: {name: 'default', roles: ['haven']},
    loadChildren: () => import('./Lazy-loaded-Modules/Restaurant-Services/table-booking/table-booking.module').then(m => m.TableBookingModule),
    canActivate: [AuthGuard]
  },

  // Table Booking for haven
  {
    path: "gift-meal",
    data: {name: 'default', roles: ['haven']},
    loadChildren: () => import('./Lazy-loaded-Modules/Restaurant-Services/gift-meal/gift-meal.module').then(m => m.GiftMealModule),
    canActivate: [AuthGuard]
  },

  //  Shipping Delivery page
  {
    path: 'shipping-and-delivery',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/shipping-delivery/shipping-delivery.module').then(m => m.ShippingDeliveryModule),
    canActivate: [AuthGuard]
  },

  //  Terms and Conditions page
  {
    path: 'terms-and-condition',
    data: {name: 'default', roles: ['all']},
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/terms-and-condition/terms-and-conditon.module').then(m => m.TermsAndConditionModule),
    canActivate: [AuthGuard]
  },

  { 
    path: "product-listing", 
    data: {name: 'default', roles: ['nesma']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/Product-Modules/product-lists/product-list-customize/product-list-customize.module').then(m => m.ProductsListCustomizeModule), 
    canActivate: [AuthGuard] 
  },

  { 
    path: "cart-new", 
    data: {name: 'default', roles: ['nesma']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/Cart-module/cart-new/cart-new.module').then(m => m.CartNewModule), 
    canActivate: [AuthGuard] 
  },
  
  { 
    path: "knowily", 
    data: {name: 'default', roles: ['tams']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/Product-Modules/produt-qr-listing/product-qr-listing.module').then(m => m.ProductQRListingModule), 
    canActivate: [AuthGuard] 
  },
  { 
    path: "company-details/:id", 
    data: {name: 'default', roles: ['aiema','cle', 'bombayhardware']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/company-details/company-product-details/company-product-details.module').then(m => m.companyProductDetailsModule), 
    canActivate: [AuthGuard] 
  },
  { 
    path: "company-details1/:id", 
    data: {name: 'default', roles: ['aiema', 'cle','bombayhardware']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/static-pages/company-details/company-details/company-details.module').then(m => m.CompanyDetailsModule), 
    canActivate: [AuthGuard] 
  },
  
  
  { 
    path: "category-listing", 
    data: {name: 'default', roles: ['aiema','cle','beaubelle', 'bombayhardware']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/category-listings/category-listings.module').then(m => m.CategoryListingsModule), 
    canActivate: [AuthGuard] 
  },
  

  { 
    path: "enquiry-status", 
    data: {name: 'default', roles: ['aiema','cle','beaubelle','bombayhardware']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/Enquiry-Status/enquiry-status.module').then(m => m.EnquiryStatusModule), 
    canActivate: [AuthGuard] 
  },
  
  
  { 
    path: "blogs", 
    data: {name: 'default', roles: [ 'deera','accel','karupattiraja', 'aiema','cle','linenraj','specialwires','beaubelle', 'bombayhardware']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/blog/blog.module').then(m => m.BlogModule), 
    canActivate: [AuthGuard] 
  },
   
  { 
    path: "doct_talk", 
    data: {name: 'default', roles: [ 'beaubelle','vimpro']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/doct-talk-video/doctors-talk-video.module').then(m => m.DoctorsTalkVideoModule), 
    canActivate: [AuthGuard] 
  },

  { 
    path: "sizechart", 
    data: {name: 'default', roles: [ 'deera','karupattiraja']}, 
    loadChildren: () => import('./Lazy-loaded-Modules/sizechart/sizechart.module').then(m => m.SizeChartModule), 
    canActivate: [AuthGuard] 
  },
  
];



@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Injector],
      multi: true
    }
  ],
  exports: [RouterModule]
 })
export class AppRoutingModule { }


export function initApp(injector: Injector) {
  return () => {
    return new Promise<void>((resolve) => {
      const orgId = environment.orgId;
      const filteredRoutes = routes.filter((r:any) => {
        return r.data.roles.indexOf('all') !== -1 || r.data.roles.indexOf(orgId) !== -1;
      });
      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);
      resolve();
    });
  };
}
